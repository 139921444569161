<template>
  <div class="viewer">
    <viewer
      :images="images"
      :options="options"
      @inited="inited"
      class="viewer"
      ref="viewer"
      v-bind="$attrs"
      v-on="{ ...$listeners }"
    >
      <img
        v-for="src in images"
        alt="img-preview"
        class="image"
        :src="src"
        :key="src"
      />
    </viewer>
  </div>
</template>
<script>
import { component as Viewer } from 'v-viewer';

export default {
  components: {
    Viewer,
  },
  props: {
    images: {
      type: Array,
    },
    imageClass: {
      type: [String, Object, Array],
    },
  },
  data() {
    return {
      options: {
        fullscreen: false,
        movable: false,
        navbar: false,
        reset: false,
        rotatable: false,
        scalable: false,
        title: false,
        transition: false,
      },
    };
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    show() {
      this.$viewer.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  height: rem-calc(350px);
}
</style>
